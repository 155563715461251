@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Jost&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pangolin&display=swap");
@import url('https://fonts.googleapis.com/css2?family=ZCOOL+KuaiLe&display=swap');


@keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-fadeInUp {
    animation: fadeInUp 1.5s ease-out;
  }